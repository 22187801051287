import cookie from 'react-cookies';
import {navigate} from 'gatsby';

export const triggerAuthentication = (setAuthInfo, setIsLoading, routerLink) =>{
  setIsLoading(true);
  const script = document.createElement('script');
  script.src = 'https://apis.google.com/js/api.js';
  script.async = true;
  document.body.appendChild(script);
  const SCOPE = 'https://www.googleapis.com/auth/userinfo.profile';
  setTimeout(() => {
    gapi.load('client:auth2', ()=>initClient(SCOPE,
        setAuthInfo, setIsLoading, routerLink));
  }, 5000);
};

const initClient = (SCOPE, setAuthInfo, setIsLoading, routerLink) => {
  let GoogleAuth;
  gapi.client.init({
  // eslint-disable-next-line max-len
    'clientId': '330091606787-i9erdkf859v0e0nb0h7pa9pcmd0n7pur.apps.googleusercontent.com',
    'scope': SCOPE,
  }).then(function() {
    GoogleAuth = gapi.auth2.getAuthInstance();
    setAuthInfo(GoogleAuth);
    if (GoogleAuth) {
      setTimeout(() => {
        setSigninStatus(GoogleAuth, SCOPE, setIsLoading, routerLink);
      }, 3000);
    } else {
      setIsLoading(false);
    }
  }).catch(function(error) {
    console.log('googleauth', error);
    setIsLoading(false);
  });
};

/**
     *
     *
     * @param {*} GoogleAuth
     * @param {*} SCOPE
     * @param {*} setIsLoading
     * @param {*} routerLink
     */
const setSigninStatus = (GoogleAuth, SCOPE, setIsLoading, routerLink) => {
  const user = GoogleAuth.currentUser.get();
  const isAuthorized = user.hasGrantedScopes(SCOPE);
  setIsLoading(false);
  if (isAuthorized) {
    const userProfile = user.getBasicProfile();
    const userToken = user.getAuthResponse();
    if (userProfile.getEmail().indexOf('@shawacademy.com') > -1 ||
    userProfile.getEmail().indexOf('@upskillist.com') > -1) {
      if (!userProfile || !userToken) {
        GoogleAuth.signOut();
        return;
      }
      if (userToken.expires_at < Date.parse(new Date())) {
        GoogleAuth.signOut();
        return;
      }
      setCookieValue('agent_name', userProfile.getName());
      setCookieValue('agent_email', userProfile.getEmail());
      setCookieValue('auth_token', userToken);
      navigate(routerLink);
    } else {
      setIsLoading(false);
      GoogleAuth.signOut();
    }
  } else {
    setIsLoading(false);
    GoogleAuth.signOut();
  }
  return;
};

const setCookieValue = (key, value) => {
  const expires = new Date;
  expires.setMinutes(expires.getMinutes() + 480);
  cookie.save(key, value, {
    path: '/',
    domain: process.env.CookieDomain,
    expires,
  });
};
