import styled from 'styled-components';

const size = {
  mobileSize: '600px',
  tabSize: '768px',
};
export const device = {
  mobileS: `(max-width: ${size.mobileSize})`,
  tabSize: `(max-width: ${size.tabSize})`,
};

export const ColumnFlexWrapper = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
min-height:calc(100vh - 183px);
width: 100%;
`;
export const RowFlexWrapper = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
width:100%;
`;

export const Button = styled.button`
padding:10px;
color:white;
broder-radius: 50px;
border:none;
margin-top:${(props) => props.margin0 ? '0' : '10px'};;
background:#21243d;
width: 198px;

@media ${device.mobileS} {
  width: 148px;
  }
@media ${device.tabSize} {
    width: 148px;
  }

  &:disabled {
    cursor: not-allowed
  }
`;

export const Input = styled.input`
border:1px solid #cccccc;
display:flex;
justify-content:center;
width:${(props) => props.width30 ? '30%' : '100%'};;
padding:5px;
`;

export const DisplayLayoutContainer = styled.div`
border:1px solid #cccccc;
padding:20px;
box-shadow: 0 1px 4px 0 #999;
font-size:14px;
background-color:#ffffff;
text-align:center;
border-radius:5px;
margin: 10px 0px;
width: ${(props) => props.width30 ? '30%' : 'calc(100vw - 183px)'};

@media ${device.mobileS} {
  width: 80%;
  }
`;

export const ChildDivContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background: #f6f7f9;
    padding-right: 50px;
    @media ${device.mobileS} {
      flex-direction: column;
    }
`;

export const ChildDivDisplay = styled.div`
    background: #f6f7f9;
    border-radius: 3px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    @media ${device.mobileS} {
      flex-direction: column;
      }
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-wrap:wrap;
    background: #f6f7f9;
    padding: 5px 10px;
    @media ${device.mobileS} {
      padding: 2px;
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const KeyStyle = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
`;

export const Title = styled.div`
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.19;
`;
export const BasicInfoWrapper = styled.div`
    width:50%;
    text-align:left;
    display:flex;

    @media ${device.mobileS} {
      width: 100%;
      }
    @media ${device.tabSize} {
        width: 100%;
      }
`;
export const UserNotFound = styled.p`
background-color:#f8d7da;
color:#721c24;
border:1px solid #f5c6cb;
font-size:12px;
padding:5px;
border-radius:3px;
`;
